<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
          </slot>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body">
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="button__modal"
              @click="close"
              aria-label="Close modal"
            >
              <div class="button__label">Хорошо</div>
              <div class="button__circle">
                <img
                  src="../../assets/icons/arrow-next.svg"
                  class="arrow"
                  alt="Next arrow"
                  loading="lazy"
                />
              </div>
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.54);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
  &:hover{
    cursor: pointer;
  }
}

.modal {
  z-index: 2000;
  background: rgba(22, 22, 22, 0.65);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(34px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  width: calc(100% - 20px);
  max-width: 500px;
  @include for-tablet-portrait-up {
    padding: 40px 30px;
  }
  @include for-tablet-landscape-up {
    padding: 50px 25px;
    max-width: 550px;
  }
  @include for-desktop-up {
    padding: 50px 40px;
    max-width: 580px;
  }
  &-header,
  &-footer {
    display: flex;
  }
  &-header {
    color: var(--white-color);
    justify-content: center;
    position: relative;
    // text-decoration: underline;
    @include P2();
    @include for-tablet-portrait-up {
      @include P1();
    }
    @include for-tablet-landscape-up {
      @include H4();
    }
    @include for-desktop-up {
      @include H2();
    }
    &::after {
      display: block;
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      width: 60px;
      height: 3px;
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      content: "";
      background: $dark-blue-color;
      box-shadow: 0px 1px 4px rgba(14, 129, 232, 0.678);
      transition: width 0.3s ease-out;
      @include for-tablet-portrait-up {
        width: 80px;
      }
      @include for-tablet-landscape-up {
        top: 40px;
      }
      @include for-desktop-up {
        top: 60px;
      }
    }
  }
  &-footer {
    justify-content: center;
  }
  &-body {
    padding-top: 54px;
    padding-bottom: 60px;
    justify-content: center;
    color: var(--white-color);
    @include P4();
    text-align: center;
    @include for-tablet-portrait-up {
      padding-top: 58px;
      padding-bottom: 52px;
    }
    @include for-tablet-landscape-up {
      @include P2();
      padding-top: 60px;
      padding-bottom: 42px;
    }
    @include for-desktop-up {
      @include P2();
      padding-top: 60px;
      padding-bottom: 61px;
    }
  }
}

.button__modal {
  @include modal__btn-primary();
  .button__circle {
    @include form-btn__circle();
    background: var(--white-color);
  }
  .button__label {
    display: flex;
    width: calc(100% - 32px);
    justify-content: center;
    text-align: center;
    @include for-tablet-portrait-up {
      width: calc(100% - 36px);
    }
    @include for-tablet-landscape-up {
      width: calc(100% - 40px);
    }
  }
}
</style>
